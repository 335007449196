import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "amfe-flexible/index"
// 引入ElementUI组件库
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入登录滑块验证插件
import SlideVerify from 'vue-monoplasty-slide-verify';
import { showErrorNotification, showErrorMessage } from '@/components/message/message.js'
// 引入echarts图
import * as echarts from 'echarts';
Vue.prototype.$showErrorNotification = showErrorNotification
Vue.prototype.$showErrorMessage = showErrorMessage
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(SlideVerify)
Vue.prototype.$echarts = echarts;
Vue.prototype.fontSize = function (res) {
  // 获取视口宽度
  const clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  if (!clientWidth) return; // 如果获取不到视口宽度，则退出函数
  // 计算基准字体大小
  let fontSize = clientWidth / 1920;
  // 返回根据视口宽度计算出的相对字体大小
  console.log(res * fontSize);
  return res * fontSize;
};
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
