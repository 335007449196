const state = {
    coursesShow: null,
    manual_data: {},
    userInfo_obj: {}
}
const getters = {
    manual_data: (state) => state.manual_data,
    userInfo_obj: (state) => state.userInfo_obj,
}
const mutations = {
    setuser: (state, val) => {
        state.coursesShow = val
    },
    MANUAL_DATA: (state, val) => {
        state.manual_data = val
    },
    USERINFO_OBJ: (state, val) => {
        state.userInfo_obj = val
    },
}
export default {
    namespaced: true,
    state,
    getters,
    mutations
}