// 引入request
import request from './http'
// 登录接口
export const userLogin = (params) => {
    return request({
        //请求地址，会自动匹配baseurl
        url: '/api/User/CheckLogin',
        // 请求方式
        method: 'POST',
        //请求参数
        data: params
    })
}
// 导入成绩
export const fileScores = params => {
    return request({
        url: '/api/UserSubject/UserImportExcel',
        method: 'POST',
        headers: {
            "Content-Type": "multipart/form-data"
        },
        data: params
    })
}
// 获取初选时间
export const primaryTimeApi = params => {
    return request({
        url: '/api/UserSubject/GetHomeTimeStart',
        method: 'GET',
        params
    })
}

// 查询当前登录学生成绩
export const getStudentMarkApi = params => {
    return request({
        url: '/api/UserSubject/GetUserScore',
        method: 'GET',
        params
    })
}
// 查询当前院校选科时间
export const getSelectionTimeApi = params => {
    return request({
        url: '/api/UserSubject/GetSubjectTime',
        method: 'GET',
        params
    })
}
// 获取当前所有选科
export const getAllSubjectApi = params => {
    return request({
        url: '/api/UserSubject/GetSubjects',
        method: 'GET',
        params
    })
}
// 学生提交选科
export const setStudentApi = params => {
    return request({
        url: '/api/UserSubject/InsertStuSubject',
        method: 'POST',
        data: params
    })
}

// 学生提交选科
export const manualQuery = params => {
    return request({
        url: '/api/UserSubject/AddStudentRankings',
        method: 'POST',
        data: params
    })
}

// 查询学生手动输入成绩历史记录
export const historyRanking = params => {
    return request({
        url: '/api/UserSubject/GetStudentRankings',
        method: 'GET',
        params
    })
}
// 获取当前登录用户信息
export const getUserInfo = params => {
    return request({
        url: '/api/User/GetUserInfo',
        method: 'GET',
        params
    })
}

// yt登录
export const ytLogin = params => {
    return request({
        url: '/app/User/YtSSoLogin',
        method: 'GET',
        params
    })
}