<template>
  <div>
    <div
      style="width: 100vw"
      v-loading.fullscreen.lock="loadingAll"
      element-loading-background="rgba(0, 0, 0, 0.6)"
    ></div>
  </div>
</template>
<script>
export default {
  computed: {
    loadingAll() {
      return this.$store.state.loadingAll
    }
  }
}
</script>
