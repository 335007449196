<template>
  <div class="nav">
    <div class="heder_box">
      <div class="heder">
        <div class="heder-message">
          <div class="message-left">
            <div>{{ userInfo_obj.schoolName }}</div>
          </div>
          <div class="message-right">
            <p>账号: &nbsp;{{ userInfo_obj.userName }}</p>
            <span class="username">{{ userInfo_obj.trueName }}</span>
            <span>{{ userInfo_obj.stuGradeClass }}</span>
            <div class="logout" @click="outLogin">
              <span>退出登录</span>
              <i class="el-icon-switch-button"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="nav-list">
      <div class="concer">
        <ul>
          <li
            :class="currNav == item.path || curridx == index ? 'actions' : ''"
            v-for="(item, index) in navList"
            :key="index"
            @click="changeNav(index, item.path)"
          >
            {{ item.title }}
          </li>
        </ul>
      </div>
    </div>
    <div class="crumbs">
      <el-breadcrumb separator=">">
        <el-breadcrumb-item v-for="(item, index) in lists" :key="index">
          <router-link
            :to="item.meta.title == '校园内网首页' ? '/' : item.path"
            >{{ item.meta.title }}</router-link
          >
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { getUserInfo } from '@/request/api.js'

export default {
  data() {
    return {
      navList: [
        { title: '选科系统首页', path: '/home' },
        { title: '正式选科', path: '/official' },
        { title: '选科资讯', path: '/information' },
        { title: '以兴趣选科目', path: '/interest' },
        { title: '院校专业选科参考', path: '/courses' },
        { title: '成绩选科参考', path: '/reference' }
      ],
      courseArr: [
        { path: '/courses', text: '以专业选科目' },
        { path: '/courses', text: '以学校选科目' },
        { path: '/courses', text: '以科目选专业' },
        { path: '/courses', text: '以科目选学校' }
      ],
      style: {
        display: 'none'
      },
      currNav: '/home',
      curridx: null,
      lists: [],
      userInfo_obj: {}
    }
  },
  watch: {
    $route(to, from) {
      this.lists = to.matched
      this.currNav = to.path
      if (this.currNav == '/courses') {
        this.curridx = null
      } else if (to.path === '/information/detail_page') {
        this.curridx = 2
      } else {
        this.curridx = null
      }
    },
    curridx: {
      handler(newValue, oldValue) {}
    }
  },

  computed: {
    ...mapState('user', ['coursesShow'])
  },

  created() {
    this.lists = this.$route.matched //获取路由内的全部信息
    const token = localStorage.getItem('token')
    if (token) {
      this.userInfo()
    }
  },
  mounted() {},
  methods: {
    ...mapMutations('user', ['setuser', 'USERINFO_OBJ']),
    // 退出登录
    outLogin() {
      localStorage.removeItem('token')
      localStorage.removeItem('userInfo')
      this.$router.replace('/login')
      this.$showErrorMessage('success', '退出成功')
    },
    async userInfo() {
      const res = await getUserInfo()
      this.userInfo_obj = res.data
      this.USERINFO_OBJ(res.data)
      localStorage.setItem('userInfo', JSON.stringify(res.data))
    },
    changeNav(index, route) {
      this.curridx = index
      this.setuser(0)
      // this.coursesShow = 0;

      this.style.display = 'none'
      this.$router.push({ path: route })
    },
    mouseOver(v, i) {
      if (i == 4) {
        this.style.display = 'block'
      } else {
        this.style.display = 'none'
      }
      if (v == '/courses') {
        this.style.display = 'block'
      }
    },
    mouseLeave(i) {
      this.style.display = 'none'
    },
    // 修改当前项的li
    changeCurr(index, route) {
      this.$router.replace({ path: route })
      this.setuser(index)
    }
  }
}
</script>

<style lang="scss" scoped>
.nav {
  width: 100%;
  .heder_box {
    position: relative;
    height: 300px;
    .heder {
      width: 100%;
      height: 100%;
      background: url(../../assets/bannerTop.png) no-repeat;
      box-sizing: border-box;
      background-size: 100% 100%;
      .heder-message {
        width: 1000px;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        .message-left {
          font-size: 40px !important;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 70px;
        }
        .message-right {
          color: #ffffff;
          p {
            margin: 8px 0px;
          }
          .username {
            margin-right: 20px;
          }
          .logout {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 106px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            border-radius: 8px;
            border: 1px solid #ffffff;
            color: #ffffff;
            margin-top: 8px;
            cursor: pointer;
            span {
              margin-right: 6px;
            }
            .el-icon-switch-button {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  .nav-list {
    width: 1000px;
    height: 68px;
    margin-top: 20px;
    background: #ffffff;
    margin: 0 auto;
    margin-top: 10px;
    .concer {
      width: 1000px;
      border-radius: 12px 12px;
      box-shadow: 0px 4px 16px 0px rgba(47, 84, 235, 0.16);
      height: 60px;
      position: relative;

      ul {
        height: 60px;
        line-height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #333;
        font-weight: 600;
        li {
          width: 167px;
          text-align: center;
          height: 60px;
          cursor: pointer;
        }
        /*        li:hover {
          color: #426efd;
        } */
        .actions {
          width: 188px;
          height: 68px;
          line-height: 68px;
          color: #ffffff;
          font-weight: 600;
          background: #426efd;
          border-radius: 12px 12px 12px 12px;
        }
      }
      .frame-box {
        position: absolute;
        z-index: 99;
        top: 60px;
        right: 170px;
        width: 167px;
        height: 129px;
        background: #ffffff;
        box-shadow: 0px 4px 16px 0px rgba(47, 84, 235, 0.16);
        transition: all 0.3 linear;
        ul {
          display: flex;
          flex-direction: column;

          li {
            width: 167px;
            height: 32px;
            line-height: 32px;
            background: #ffffff;
            color: #666666;
            font-size: 14px;
          }
          li:hover {
            color: #426efd;
            background: #eff3ff;
          }
          .action-li {
            color: #426efd;
            background: #eff3ff;
          }
        }
      }
    }
  }

  .crumbs {
    width: 1000px;
    margin: 16px auto;
    ::v-deep .el-breadcrumb__inner.is-link {
      cursor: pointer;
    }
  }
}
</style>
