import Vue from 'vue'
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/home',
    meta: { title: '校园内网首页' },
    component: () => import(/* webpackChunkName: "about" */ '../views/Home/home.vue'),
    children: [
      {
        path: 'home',
        meta: { title: '智能辅助选课首页' },
        component: () => import(/* webpackChunkName: "about" */ '../views/Assisthome/index.vue'),
      },
      {
        path: 'home/concept',
        name: 'HomeConcept',
        meta: { title: '选科概念介绍' },
        component: () => import(/* webpackChunkName: "about" */ '../views/Assisthome/concept.vue')
      },
      {
        path: 'interest',
        name: 'Interest',
        meta: { title: '以兴趣选科目' },
        component: () => import(/* webpackChunkName: "about" */ '../views/Interest/interest.vue'),

      },
      {
        path: 'ytsso',
        name: 'Ytsso',

        component: () => import(/* webpackChunkName: "about" */ '../views/Assisthome/ytsso.vue'),

      }
    ]
  },
  {
    path: '/official',
    name: 'Official',
    meta: { title: '校园内网首页' },
    component: () => import(/* webpackChunkName: "about" */ '../views/Official/official.vue'),
    children: [{
      path: '/official',
      meta: { title: '正式选科' },
      component: () => import(/* webpackChunkName: "about" */ '../views/Official/official.vue'),
    }]

  },
  {
    path: '/courses',
    meta: { title: '校园内网首页' },
    component: () => import(/* webpackChunkName: "about" */ '../views/Courses/courses.vue'),
    children: [{
      path: '/courses',
      meta: { title: '以专业选科目' },
      component: () => import(/* webpackChunkName: "about" */ '../views/Courses/courses.vue'),
    }]
  },
  {
    path: '/reference',
    meta: { title: '校园内网首页' },
    component: () => import(/* webpackChunkName: "about" */ '../views/Reference/reference.vue'),
    children: [{
      path: '/reference',
      name: 'Reference',
      meta: { title: '以专业选科目' },
      component: () => import(/* webpackChunkName: "about" */ '../views/Reference/reference.vue'),
    }]
  },
  {
    path: '/information',
    /* name: 'Information', */
    meta: { title: '校园内网首页' },
    component: () => import(/* webpackChunkName: "about" */ '@/views/information/information.vue'),
    children: [
      {
        path: '',
        name: '',
        meta: { title: '选科资讯' },
        component: () => import(/* webpackChunkName: "about" */ '@/views/information/index.vue'),
      },
      {
        path: '',
        name: 'Information',
        meta: { title: '选科资讯' },
        component: () => import(/* webpackChunkName: "about" */ '@/views/information/details.vue'),
        children: [
          {
            path: 'detail_page',
            name: 'DetailPage',
            meta: { title: '相关资讯' },
            component: () => import(/* webpackChunkName: "about" */ '@/views/information/detail_page.vue'),
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/login.vue'),
    meta: {
      showNavMenu: true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "about" */ '../views/NotFound/NotFound.vue'),
  }


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  if (to == "/home/ytsso") {
    next();
    return;
  }
  const token = localStorage.getItem("token");

  // 路由拦截，判断是否需要登录
  const routesWithoutAuthentication = ['/home', '/'];
  const routesRequiringAuthentication = ['/login'];

  if (routesWithoutAuthentication.includes(to.path) && !token) {
    next({ path: '/login' });
  } else if (routesRequiringAuthentication.includes(to.path) && token) {
    next({ path: '/home' });
  } else {
    next();
  }
});

export default router
