<template>
  <div id="app">
    <Nav v-if="!$route.meta.showNavMenu" class="nav-t"></Nav>
    <router-view></router-view>
    <Loading></Loading>
  </div>
</template>
<script>
import Nav from '@/views/nav/nav.vue'
import Loading from '@/components/loading/loading.vue'
export default {
  components: { Nav, Loading },
  data() {
    return {}
  },
  mounted() {
    // 获取设备宽度
    var screenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    // 设置根元素的字体大小
    document.documentElement.style.fontSize = screenWidth / 12 + 'px'
  },
  methods: {}
}
</script>
<style lang="scss">
@media only screen and (max-width: 768px) {
  html,
  body {
    font-size: 12px !important;
    /* 例如，将根元素的字体大小设置为10px，可以根据需要调整 */
  }
}
html,
body {
  font-size: 16px !important;
}

* {
  padding: 0;
  margin: 0;
  list-style: none;
}
::-webkit-scrollbar {
  background: transparent;
  width: 0px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background-color: #e1e1e1;
  width: 0px;
  height: 6px;
  border: 2px solid transparent;
  background-clip: content-box;
}
</style>
