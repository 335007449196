/* 提示弹窗封装 */

import { Notification, Message } from 'element-ui';

/* Notification 通知  悬浮出现在页面角落，显示全局的通知提醒消息。*/
export function showErrorNotification (type, message) {
    Notification.error({
        title: type,
        message: message,
    });
}
/* Message 消息提示 */
export function showErrorMessage (type, message) {
    Message({
        showClose: true,
        type: type,
        message: message,
    });
}