import axios from 'axios';
import Vue from 'vue';
import VueRouter from 'vue-router'
const router = new VueRouter({});
const service = axios.create({
    baseURL: 'http://172.16.0.137:5081',
    timeout: 50000
})
// 请求拦截器
service.interceptors.request.use(
    config => {
        // 配置请求头
        config.headers['Authorization'] = `Bearer ${localStorage.getItem('token') ? localStorage.getItem('token') : ''}`
        // 在发送请求前要做的事儿
        return config
    },
    err => {
        // 在请求错误时要做的事儿

        return Promise.reject(err)
    }
)
// 响应拦截器
service.interceptors.response.use(
    res => {
        if (res.data.status == 1) {
            return res.data;
        } else if (res.data.Fail == 0 || res.data.status == 401) {
            localStorage.removeItem("token")
            location.href = "/login"
        }
    },
    err => {
        Vue.prototype.$message({
            message: "错误响应，请重新登录！",
            type: 'error'
        });
        /*   localStorage.removeItem("token")
          setTimeout(() => {
              location.href = "/login"
          }, 500) */

        // 在请求错误时要做的事儿
        return Promise.reject(err);
    }
);
export default service;

